.modal-files {
  width: 80%;
  padding: 10px;
}

.modal-files-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-modal{
  float: left;
}