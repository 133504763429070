.approve-check{
    text-align: left;
}

.approve-check label{
    margin-left:0px;
}

.approve-check label span{
    font-weight: 600;
    font-size: 16px;
}