.main-login-form{
    margin: 5% auto;
    max-width: 400px;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 20px;
}
.login-bg{
    background-image: url('../../assets/img/bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%; 
    width:100%;
    top:0;
    position: absolute;
  }
  .input-rtl label{
      left:auto;
      right:0;
      transform-origin: top right;
  }
  .elem-holder{
      margin:0px auto;
      max-width: 300px;
  }
