.thankyou {
  text-align: center;
}

.thankyou-info-container {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.thankyou-info-container ul{
  list-style: none;
  padding: 0px;
}

.column {
  flex: 1;
}

.column input{
}

ul{
  list-style: none;
}


@media (max-width: 400px) {
  .thankyou-info-container {
    display: block;
  }
}
