.footer{
    position: fixed;
    width:100%;
    padding: 10px 0px;
    bottom: 0;
    right: 0;
    background-color: #141516;
    display:flex;
    justify-content: space-around;
}   
.footer div{
    flex:1;
    text-align: center;
}

