.main-info-form {
  padding-bottom: 150px;
}
.logo-school {
  padding: 10px;
  max-width: 80px;
}

.header-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.child-full-name {
  font-size: 1.5rem;
}

.steper-holoder {
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
}
.buttons-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top:20px;
}
.steper-holoder div {
  padding: 10px;
}

.form-info-holder {
  padding: 10px;
}

.form-info-holder > div,
.form-info-holder > div {
  padding: 0px;
}

.inner-text {
  padding: 10px;
}

.steper-container{
    margin: 20px 0px;
}

.steper-container > div > span > span {
  margin-left: 5px;
  margin-right: 0px;
}

@media only screen and (max-width: 600px) {
  .text-steper > span > span {
    font-size: 11px;
  }
  .text-steper > span > svg {
    max-width: 20px;
  }

  .steper-container > div {
    padding-right: 2px;
  }
}
