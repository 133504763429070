.user-edit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  padding:10px;
  flex: 1;
}

.sum-payments-paper{
  padding: 5px;
}

.preloader-end{
  position: absolute;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
}

.hidden{
  display: none !important;
}

@media (max-width: 400px) {
  .user-edit {
    display: block;
  }
}