.tooltip-info {
  padding: 3px 6px;
  background: #2196f3;
  color: white;
  margin-right: 6px;
  display: inline-block;
  cursor: pointer;
}

.msg-bottom{
    margin-top:10px;
}

.info-window{
  background-color: white !important;
  color:black !important;
  font-size: 14px !important;
  min-width: 460px;
}

.radio-payment{
  flex-direction: row !important;
}

.subtotal{
  margin-top: 20px;
  padding: 10px;
  font-size: 1.3rem;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  table > thead > tr > th,
  table > tbody > tr > td {
    padding: 5px !important;
  }

  table label {
    margin-left: 0px !important;
  }
}
